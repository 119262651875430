import './Bridge.css';
import { SelectionProvider } from "../contexts/SelectionContext";
import { useTxBroadcast } from "../contexts/TxBroadcastContext";
import { useCallback, useEffect, useState, useRef } from "react";
import AccountDetails from "./AccountDetails";
import InputAmount from "./InputAmount";
import SelectionFlow from './SelectionFlow';
import CircularProgress from '@mui/joy/CircularProgress';

import { FormatAccount, NormalizeBalance } from "../lib/utils";

import { Tooltip } from 'react-tooltip'
import { Checkmark } from 'react-checkmark'
import PulseLoader from "react-spinners/PulseLoader";
import { FaAnglesDown, FaB } from "react-icons/fa6";
import { FaBan } from "react-icons/fa6";
import { useContractStore } from '../store/contractStore';
import { useWalletsStore } from '../store/walletStore';
import { Send } from '../lib/send';
import { Alert } from '@mui/joy';
import Header from './Header';
import Footer from './Footer';


function Bridge() {
  const [ amount, setAmount ] = useState(0);
  const [ balanceTooLow, setBalanceTooLow ] = useState(false);
  const [ ourFees, setOurFees ] = useState(0);
  const [ networkFees, setNetworkFees ] = useState(0);
  const [ sending, setSending ] = useState(false);

  const sourceState = useContractStore((state) => state.source);
  const destinationState = useContractStore((state) => state.destination);
  const setSourceTokensToSend = useContractStore((state) => state.setSourceTokensToSend);

  const sourceWalletBalance = useWalletsStore((state) => {
    const wallet = sourceState?.wallet;
    const asset = sourceState?.asset;
    return NormalizeBalance(state[wallet]?.assetBalances[asset], "stacks");
  });

  const send = async () => {
    setSending(true);
    const txId = await Send();
    const sourceProtocol = sourceState?.protocol;
    const destinationProtocol = destinationState?.protocol;
    window.location.href = `/${sourceProtocol}/${txId}/${destinationProtocol}`;
  };

  const set = (event) => {
    setAmount(NormalizeBalance(Number(event.target.value)), "stacks");
  }

  useEffect(() => {
    if (sourceState && isNaN(sourceWalletBalance) || sourceWalletBalance < amount) {
      setBalanceTooLow(true);
    } else {
      setSourceTokensToSend(amount);
      setBalanceTooLow(false);
    }
  }, [amount]);

  return (
    <div class="bridge">
      <Header />
      <div class="announcement">
        <em>Bridge between Stacks and Solana effortlessly.</em>
      </div>
      <div class="order-wrapper">
        <div class="box-top"></div>
        <div class="order-input">
          <div class="title">
            From
          </div>
          <SelectionProvider type="source">
            <SelectionFlow type="source" />
          </SelectionProvider>
          <div class={`${sourceState ? "" : "hidden"}`}>
            <AccountDetails contract={sourceState} />
            <div class={`${ true ? "account-info" : "hidden"}`}>
              <label>send</label>
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                width: '16em'
              }}>
                <div style={{
                  height: '2em',
                }}>
                  <input type="number" onChange={set} placeholder={0} />
                </div>

                <div class={balanceTooLow ? `error` : `no-error`}>
                  That amount is greater than your account balance.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="box-bottom"></div>
      </div>
      <div style={{
        padding: '1em',
      }}>
        <FaAnglesDown size={30} color="white" />
      </div>
      <div class="order-wrapper">
        <div class="box-top"></div>
        <div class={`order-input`}>
          <div class="title">
            To 
          </div>
          <SelectionProvider type="destination">
            <SelectionFlow type="destination" />
          </SelectionProvider>
          <div class={`${destinationState ? "" : "hidden"}`}>
            <AccountDetails contract={destinationState} />
            <div class={`${true ? "account-info" : "hidden"}`}>
              <label>Bridge Fee</label>
              {ourFees}
            </div>
            <div class={`${true ? "account-info" : "hidden"}`}>
              <label>Network Fee</label>
              {networkFees}
            </div>
            <div class={`${true ? "account-info" : "hidden"}`}>
              <label>receive</label>
              <strong>{amount}</strong>
            </div>
          </div>

          <div class={`${destinationState && !balanceTooLow && amount > 0 ? "quote-info" : "hidden"}`}>
            <a class={!sending ? `button waiting-pulse` : `hidden`} onClick={send}>Send</a>
            <div class={sending ? `button sending` : `hidden`}>
              <div class="hidden"></div>
              <CircularProgress color="neutral" variant="plain" size="sm" />
            </div>
          </div>
        </div>
        <div class="box-bottom"></div>
      </div>
      <Footer />
    </div>
  );
}

export default Bridge;