import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { IoClose } from "react-icons/io5";
import { FaXmark } from "react-icons/fa6";

import './Modal.css';

function Modal({ title, selectionKey, options, open, setModalOpen, setModalSelection }) {
  const modalRef = useRef();

  const closeModal = () => {
    setModalOpen(false);
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    const handleEscape = (event) => {
      if (event.key === "Escape") {
        closeModal();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscape);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscape);
    };
  }, [setModalOpen]);

  const OptionsList = useCallback(() => {
    if (!options || !Object.keys(options).length) {
      return <div>No options available</div>;
    }

    const renderedOptions = Object.entries(options).map(([key, { icon }]) => {
      const selectOption = () => {
        setModalSelection(key);
        closeModal();
      }

      return (
        <div key={key} className="option" onClick={selectOption}>
          <div>
            <img src={icon} alt={key} />
          </div>
          <div>
            {key}
          </div>
        </div>
      )
    });
    
    return renderedOptions;
  }, [options]);

  if (!open) {
    return null;
  }

  return (
    <div ref={modalRef} class={open ? 'modal modal-visible' : 'modal modal-hidden'}>
      <div class='exit' onClick={closeModal}>
        <FaXmark size={35} />
      </div>
      <div class='modal-title'>
        {title}
      </div>
      <div class='options'>
        <OptionsList />
      </div>
    </div>
  )
}

export default Modal;