import { ConnectToLeather, STXBalance } from './leather';
import { ConnectToPhantom } from './phantom';

export const InitializeWallet = async (walletName, network) => {
  // other than name and network, the wallet must be filled
  // out after connection in the switch
  const wallet = {
    address: '',
    assetBalances: {},
    connected: false,
    name: walletName,
    network: network,
    protocol: null,
  };

  switch (walletName) {
    case 'leather':
      const userSession = await ConnectToLeather();
      const address = userSession.loadUserData().profile.stxAddress[wallet.network];
      const balance = await STXBalance(address);
      wallet.address = address;
      wallet.assetBalances['stx'] = balance;
      wallet.connected = true;
      wallet.protocol = 'stacks';
      break;
    case 'phantom':
      const phantom = await ConnectToPhantom()
      wallet.address = phantom.publicKey.toString();
      wallet.assetBalances['sol'] = phantom.balance;
      wallet.connected = true;
      wallet.protocol = 'solana';
      break;
    default:
      console.error('Wallet not supported');
      return {};
  }

  return wallet;
}