export const NormalizeBalance = (balance, protocol) => {
  if (protocol === 'stacks') {
    return balance / 10**6;
  } else if (protocol === 'solana') {
    return balance / 10**9;
  } else {
    return balance;
  }
}

export const FormatAccount = (account) => {
  if (account == undefined) {
    return;
  }
  if (account.length > 13) {
    let start = account.slice(0, 5);
    let end = account.slice(-5);
    return start + "..." + end;
  } else {
    return account;
  }
}