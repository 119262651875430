import { useEffect, useRef, useState } from 'react';

import { FaAngleDown } from "react-icons/fa6";
import { FaAngleRight } from "react-icons/fa6";
import { FaCoins } from "react-icons/fa";
import { FaRegCircle } from "react-icons/fa6";
import { FaWallet } from "react-icons/fa";

import { InitializeWallet } from '../lib/wallets/initialize';
import { Options } from '../lib/options';
import { useSelection } from '../contexts/SelectionContext';
import { useWalletsStore } from '../store/walletStore';
import { useContractStore } from '../store/contractStore';
import SelectWithModal from "./SelectWithModal";

import './SelectionFlow.css';

function SelectionFlow({ type, disable = false }) {
  const { 
    resetDestination ,
    resetSource, 
    updateDestination, 
    updateSource } = useContractStore();
  
  const source = useContractStore((state) => state.source);
  const destination = useContractStore((state) => state.destination);
  const { selection, upsertSelection } = useSelection();
  const { upsertWallet } = useWalletsStore();
  const flowRef = useRef(null);


  const filteredAssetOptions = () => {
    if (type == "source") {
      return Options[selection.protocol]?.assets || {};
    }

    const sourceAsset = source?.asset;
    const sourceProtocol = source?.protocol;
    const sourceAssetPairs = Options[sourceProtocol]?.assets[sourceAsset]?.pairs || {};
    return sourceAssetPairs;
  }

  const filteredProtocolOptions = () => {
    if (type == "source") {
      return {
        "stacks": Options["stacks"]
      };
    }

    const sourceProtocol = source?.protocol;
    const filteredOptions = {...Options};
    delete filteredOptions[sourceProtocol];
    return filteredOptions;
  }

  const initializeWallet = async (walletName) => {
    const wallet = await InitializeWallet(walletName, process.env.REACT_APP_ENVIRONMENT);
    upsertWallet(walletName, wallet);
  }

  const updateContract = async (asset) => {
    const wallet = useWalletsStore.getState()[selection.wallet];
    const contractPoint = {
      address: wallet.address,
      asset: asset,
      protocol: selection.protocol,
      network: wallet.network,
      wallet: wallet.name
    }

    if (type === "source") {
      console.log('updateSource', contractPoint)
      updateSource(contractPoint);
    } else {
      console.log('updateDestination', contractPoint)
      updateDestination(contractPoint);
    }
  }

  const resetState = () => {
    if (type === "source") {
      console.log('resetSource')
      resetSource();
      resetDestination();
    } else if (type === "destination") {
      console.log('resetDestination')
      resetDestination();
    }
  }

  useEffect(() => {
    if (type === "destination") {
      upsertSelection("protocol", null);
    }
  }, [source]);

  useEffect(() => {
    if (flowRef.current) {
      const inputs = flowRef.current.querySelectorAll('input');
      inputs.forEach(input => {
        input.disabled = disable; // Set disabled based on the prop
      });
    }
  }, [disable]);

  const disableWalletSelection = () => selection.protocol == null;
  const disableAssetSelection = () => selection.wallet  == null;

  return (
    <div ref={flowRef} class="selection-flow">
      <div>
        <SelectWithModal 
          selectionKey="protocol" 
          title="Select protocol"
          disabled={type === "destination" && source == null}
          resetState={resetState}
          icon=<FaRegCircle size={32} />
          options={filteredProtocolOptions()}/>
      </div>
      <div class="full-screen-arrow">
        <FaAngleRight color='white' size={20} />
      </div>
      <div class="small-screen-arrow">
        <FaAngleDown color='white' size={20} />
      </div>
      <div>
        <SelectWithModal 
          selectionKey="wallet"
          disabled={disableWalletSelection()}
          postSelectAction={initializeWallet}
          select="wallet" 
          resetState={resetState}
          title="Connect wallet"
          icon=<FaWallet size={32} />
          options={Options[selection.protocol]?.wallets || {}}/>
      </div>
      <div class="full-screen-arrow">
        <FaAngleRight color='white' size={20} />
      </div>
      <div class="small-screen-arrow">
        <FaAngleDown color='white' size={20} />
      </div>
      <div>
        <SelectWithModal 
          selectionKey="asset"
          disabled={disableAssetSelection()}
          postSelectAction={updateContract}
          select="asset" 
          resetState={resetState}
          title="Select asset"
          icon=<FaCoins size={32} />
          options={filteredAssetOptions()}/>
      </div>
    </div>
  );
}

export default SelectionFlow;