import React, { createContext, useContext, useState } from 'react';

const SelectionContext = createContext();

export function useSelection() {
    return useContext(SelectionContext);
}

export const SelectionProvider = ({ children, type }) => {
  const defaultState = {
    protocol: null,
    wallet: null,
    asset: null,
    amount: null
  };

  const [selection, setSelection] = useState(() => {
    return defaultState;
  });
  
  // this upsert ensures ordering of selection flow
  const upsertSelection = (key, value) => {
    console.log('upsertSelection', key, value)
    let newSelection = {...selection};
    if (key === "amount") {
      newSelection["amount"] = value;
    } else if (key === "asset") {
      newSelection["amount"] = null;
      newSelection["asset"] = value;
    } else if (key === "wallet") {
      newSelection["amount"] = null;
      newSelection["asset"] = null;
      newSelection["wallet"] = value;
    } else if (key === "protocol") {
      newSelection["amount"] = null;
      newSelection["asset"] = null;
      newSelection["wallet"] = null;
      newSelection["protocol"] = value;
    }
    setSelection(newSelection);
  };

  return (
      <SelectionContext.Provider value={{ selection, upsertSelection, setSelection }}>
        {children}
      </SelectionContext.Provider>
  );
};