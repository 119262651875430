import phantomIcon from '../assets/images/wallet-phantom.png';
import leatherIcon from '../assets/images/wallet-leather.png';
import xverseIcon from '../assets/images/wallet-xverse.png';
import solanaIcon from '../assets/images/logo-solana.svg';
import stacksIcon from '../assets/images/logo-stacks.svg';

export const Options = {
  "stacks": {
    icon: stacksIcon,
    wallets: {
      "leather": {
        icon: leatherIcon
      }
    },
    assets: {
      "stx": {
        icon: stacksIcon,
        pairs: {
          "stx": {
            icon: stacksIcon
          }
        }
      }
    }
  },
  "solana": {
    icon: solanaIcon,
    wallets: {
      "phantom": {
        icon: phantomIcon,
      }
    },
    assets: {
      "stx": {
        icon: stacksIcon,
        pairs: {
          "stx": {
            icon: stacksIcon
          }
        }
      }
    }
  }
}