import { useContractStore } from "../store/contractStore";
import { BridgeFromStacks } from "./wallets/leather";
import { FormatAccount, NormalizeBalance } from "../lib/utils";

export const Send = async () => {
  const state = useContractStore.getState();
  switch (state.source.protocol) {
    case 'stacks':
      const txData = await BridgeFromStacks(state.sourceTokensToSend * 10 ** 6, state.destination.protocol, state.destination.address);
      console.log(txData)
      return txData.txId;
    default:
      console.log('Unsupported protocol');
      return null;
  }
}