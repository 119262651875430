import React, { useCallback, useEffect } from "react";
import PulseLoader from "react-spinners/PulseLoader";
import CircularProgress from '@mui/joy/CircularProgress';
import { LinearProgress } from "@mui/joy";
import { FaXmark } from "react-icons/fa6";

import Modal from "./Modal";
import './SelectWithModal.css';
import { useSelection } from "../contexts/SelectionContext";


function SelectWithModal({ 
  selectionKey, title, icon, options, resetState, disabled = false, postSelectAction = null
}){
  const [ modalSelection, setModalSelection ] = React.useState(null);
  const [ loading, setLoading ] = React.useState(false);
  const [ modalOpen, setModalOpen ] = React.useState(false);
  const { selection, upsertSelection } = useSelection();

  const disabledClasses = disabled ? 'disabled' : '';

  const Icon = useCallback(() => {
    return modalSelection ? <img src={options[modalSelection].icon} /> : icon;
  }, [modalSelection]);

  const Name = useCallback(() => {
    return modalSelection || title;
  });

  const waiting = () => {
    if (disabled || loading) {
      return '';
    }

    if (modalSelection) {
      return '';
    }

    return 'waiting-pulse';
  }

  const selectionMade = () => {
    return modalSelection != null;
  }

  const openModal = () => {
    if (selectionMade()) return;

    setModalOpen(true);
    setLoading(true);
  }

  const cancelSelection = () => {
    upsertSelection(selectionKey, null);
    resetState();
  }

  useEffect(() => {
    async function postOperations() {
      if (modalSelection) upsertSelection(selectionKey, modalSelection);
      if (postSelectAction && modalSelection) await postSelectAction(modalSelection);
      if (!modalOpen) setLoading(false);
    }

    postOperations();
  }, [modalOpen, modalSelection]);

  useEffect(() => {
    setModalSelection(selection[selectionKey]);
  }, [selection[selectionKey]]);

  return (
    <div class={disabledClasses}>
      <div class={selectionMade() ? 'select-with-modal selected' : `select-with-modal ${waiting()}`} onClick={openModal}>
        <div class={loading ? `loading` : `hidden`}>
          <CircularProgress color="neutral" variant="soft" size="sm" />
        </div>
        <div class={!loading ? `` : `hidden no-margin`}>
          <Icon />
        </div>
        <div class={!loading ? `` : `hidden no-margin`}>
          <Name />
        </div>
        <div class={selectionMade() && !loading ? 'cancel-selection' : 'hidden no-margin'} onClick={cancelSelection}>
          <FaXmark size={15} />
        </div>
      </div>
      <Modal 
        title={title}
        selectionKey={selectionKey}
        options={options}
        open={modalOpen}
        setModalOpen={setModalOpen}
        setModalSelection={setModalSelection}
      />
    </div>
  );
}

export default SelectWithModal;