import { create } from 'zustand';

const useContractStore = create((set) => ({
  source: null,
  destination: null,
  sourceTokensToSend: 0,
  resetDestination: () => set(() => ({ destination: null })),
  resetSource: () => set(() => ({ source: null })),
  setSourceTokensToSend: (sourceTokensToSend) => set(() => ({ sourceTokensToSend })),
  updateDestination: (destination) => set(() => ({ destination })),
  updateSource: (source) => set(() => ({ source })),
}));

export { useContractStore };