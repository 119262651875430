import './App.css';
import '@fontsource/inter';
import { useEffect, useState, useRef } from 'react';
import Bridge from './components/Bridge'
import Transaction from './components/Transaction';
import ReactGA from "react-ga4";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Helmet from "react-helmet";
import frontend from './assets/images/frontend.png';

import { TxBroadcastProvider } from './contexts/TxBroadcastContext';
import { Typography } from '@mui/joy';

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Bridge />
    )
  },
  {
    path: "/:source/:txId/:destination",
    element: (
      <TxBroadcastProvider>
        <Transaction />
      </TxBroadcastProvider>
    )
  }
]);

function App() {
  useEffect(() => {
    localStorage.removeItem('blockstack-session');
    document.title = "Byphrost Bridge"
    ReactGA.initialize("G-Q123WFDDE4");
  }, []); 

  return (
    <div>
      <Helmet>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="description" key="description" content="Bridge between STX and SOL" />
        <meta name="title" key="title" content="Byphrost Bridge" />
        <meta property="og:title" key="og:title" content="Byphrost Bridge" />
        <meta property="og:locale" key="og:locale" content="en_US" />
        <meta charSet="utf-8" />
        <meta property="og:type" key="og:type" content="website" />
        <meta
          property="og:description"
          key="og:description"
          content="Bridge between STX and SOL"
        />
        <meta
          property="og:image"
          key="og:image"
          content={frontend}
        />  
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@byphrost" />
        <meta name="twitter:title" content="Byphrost Bridge" />
        <meta name="twitter:description" content="Bridge between STX and SOL" />
        <meta name="twitter:image" content={frontend} />
      </Helmet>
      <div style={{
        backgroundColor: 'black',
        color: 'white',
        padding: '.4em',
        textAlign: 'center'
      }}>
        This is a prototype. For information on usage or connecting to testnet, <strong><a href="https://scrawny-scion-8fd.notion.site/Testnet-2097012d45a5446ea61f6fd341c9f3d8" target="_blank">click here</a></strong>.
      </div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
