import { FaDiscord } from "react-icons/fa6";
import { FaGithub } from "react-icons/fa6";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaTelegram } from "react-icons/fa6";

function Footer() {
  return (
    <div class="socials">
      <a href="https://x.com/byphrost" target="_blank"><FaSquareXTwitter color="white" size={28} /></a>
      <a href="https://discord.gg/QXdKtcPkS7" target="_blank"><FaDiscord color="white" size={28} /></a>
      <a href="https://t.me/+mZ49b4Xfv8piYWEx" target="_blank"><FaTelegram color="white" size={28} /></a>
      <a href="https://github.com/byphrost"><FaGithub color="white" size={28} /></a>
    </div>
  );
}

export default Footer;