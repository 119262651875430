import logo from '../assets/images/logo-cropped.svg';

function Header() {
  return (
      <div class="header">
        <div class="logo side-piece">
          <a href="/"><img src={logo} alt="logo" height='70em' /></a>
        </div>
        <div class="header-title">
          Byphrost
        </div>
        <div class="nav side-piece">
          <div class="nav-item">
            <a href="https://forms.gle/sAvXu4PQ4KZS8nGVA" target="_blank">Transaction Explorer</a>
          </div>
          <div class="nav-item">
            <a target="_blank" href="https://scrawny-scion-8fd.notion.site/Byphrost-Bridge-6c95833f34f140b281f19b1bb36b6505" target="_blank">Documentation</a>
          </div>
          <div class="nav-item">
            <a target="_blank" href="https://forms.gle/oDCrmbV1HbsEMSV68">Support</a>
          </div>
        </div>
      </div>
  );
}

export default Header;