import { useParams } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';

import { Chip, CircularProgress } from '@mui/joy';

import { Button } from '@mui/joy';
import { useTxBroadcast } from '../contexts/TxBroadcastContext';
import { Typography } from '@mui/joy';
import { Options } from '../lib/options';

import { Badge } from '@mui/joy';

import { Link } from '@mui/joy';
import './Transaction.css';
import { FormatAccount } from '../lib/utils';
import { NormalizeBalance } from '../lib/utils';


import { Skeleton } from '@mui/joy';
import { Alert } from '@mui/joy';
import { useCallback, useEffect, useState } from 'react';
import { InitializeWallet } from '../lib/wallets/initialize';
import { MintClaim, GetWSTXClaim } from '../lib/wallets/phantom';
import { set } from '@project-serum/anchor/dist/cjs/utils/features';

const BROADCAST_URL = 'wss://testnet.byphrost.com/broadcast';

function Transaction() {
  let [ loading, setLoading ] = useState(true);
  let [ txExists, setTxExists ] = useState(false);
  let { source, txId, destination } = useParams();
  let { subscribe, lastMessage } = useTxBroadcast();
  let [ log, setLog ] = useState({});

  const [ destinationTx, setDestinationTx ] = useState(null);
  const [ sourceSubmittedSuccess, setSourceSubmittedSuccess ] = useState(null);
  const [ destinationSubmittedSuccess, setDestinationSubmittedSuccess ] = useState(null);
  const [ destinationFailed, setDestinationFailed ] = useState(null);

  const [ walletLoading, setWalletLoading ] = useState(false);
  const [ walletConnected, setWalletConnected ] = useState(false);
  const [ walletAddress, setWalletAddress ] = useState(null);

  const sourceIcon = Options[source]?.icon;
  const destinationIcon = Options[destination]?.icon;

  const stacksExplorerLink = `https://explorer.stacks.co/txid/${txId}?chain=testnet`;

  useEffect(() => {
    const checkIfExists = async () => {
      let response = await fetch(`https://api.testnet.hiro.so/extended/v1/search/${txId}?include_metadata=false`);
      let data = await response.json();
      if (!data.found) {
        return
      } 

      setTxExists(true);
      setLoading(false);
      subscribe('stacks', txId);

      response = await fetch(`https://testnet.byphrost.com/tx/stacks/${txId}`);
      data = await response.json();
      console.log(data)
      if (data) {
        setLog(data);
      } 
    };

    checkIfExists().catch((e) => e);
  }, []);

  useEffect(() => {
    if (!log) {
      return;
    }

    if (log.source_submitted_success) {
      setSourceSubmittedSuccess(log.source_submitted_success);
    }
    if (log.destination_submitted_success) {
      setDestinationSubmittedSuccess(log.destination_submitted_success);
    }
    if (log.destination_submitted_failed) {
      setDestinationFailed(log.destination_submitted_failed);
    }
    if (log.destination_tx_hash) {
      setDestinationTx(log.destination_tx_hash);
    }
  }, [log]);

  useEffect(() => {
    if (!lastMessage) {
      return;
    }
    setLog(JSON.parse(lastMessage.data));
  }, [lastMessage]);

  const [ claim, setClaim ] = useState(null);
  const [ claimPda, setClaimPda ] = useState(null);
  const [ loadingClaim, setLoadingClaim ] = useState(true);


  const loadingBlock = () => {
    return (
      <div style={{
        textAlign: 'center',
      }}>
        <CircularProgress color="neutral" size="lg" />
      </div>
    );
  };

  const txDoesntExistBlock = useCallback(() => {
    return (
      <div>
        <Typography level="h4" variant="plain">Transaction not found</Typography>
        <Typography>
          The transaction you are looking for does not exist. Please check the URL and try again.
        </Typography>
      </div>
    )
  }, []);

  const formatDateString = (dateString) => {
    const dateTimePart = dateString.match(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}\.\d+/)[0];
    const timezonePart = dateString.match(/[+-]\d{4}/);
    const formattedDateString = `${dateTimePart}${timezonePart ? ` ${timezonePart[0].slice(0, 3)}:${timezonePart[0].slice(3, 5)}` : 'Z'}`;
    return formattedDateString;
  }

  const formatDate = (date) => {
    let dateObj = new Date(formatDateString(date));
    let now = new Date();
    let diff = now - dateObj;
    let seconds = Math.floor(diff / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    let days = Math.floor(hours / 24);

    if (days > 0) {
      return `${days} days ago`;
    } else if (hours > 0) {
      return `${hours} hours ago`;
    } else if (minutes > 0) {
      return `${minutes} minutes ago`;
    } else {
      return `${seconds} seconds ago`;
    }
  }

  const connectWallet = async () => {
    setWalletLoading(true);
    const wallet = await InitializeWallet("phantom", "testnet");
    const { claim, claimPda }= await GetWSTXClaim(log.group);
    try {
      claim.amount = Number(claim.amount / 10 ** 9);
      setClaim(claim);
      setClaimPda(claimPda);
    } catch (e) {
      setClaim(null);
      console.log(e);
    }
    setWalletAddress(wallet.address);
    setWalletConnected(true);
    setLoadingClaim(false);
  };

  const [ claimingClaim, setClaimingClaim ] = useState(false);
  const [ mintTx, setMintTx ] = useState(null);
  const [ claimMinted, setClaimMinted ] = useState(false);

  const claimClaim = async (id, pda) => {
    setClaimingClaim(true);
    const tx = await MintClaim(id, pda);
    console.log(tx)
    setMintTx(tx.signature);
    setClaimingClaim(false);
    setClaimMinted(true);
  };

  const [ creatingWstxAccount, setCreatingWstxAccount ] = useState(false);

  const txExistsBlock = useCallback(() => {
    return (
      <div>
        <Typography level="h4" variant="plain">Transaction Details</Typography>
        <Typography>
          Your transaction is being processed. This page will automatically update. Use this URL to check the status of your transaction.
        </Typography>
        <div style={{
          paddingTop: '.5em',
        }}>
          <Chip>From</Chip>
        </div>
        <div style={{
          display: 'flex',
          padding: '1em',
          gap: '1em',
        }}>
          <div>
            <img src={sourceIcon} class="protocol-logo" />
          </div>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '.7em',
            width: '100%',
          }}>
            <Alert color="neutral">
              Submitted deposit to {source}: 
              <Link href={`https://explorer.hiro.so/txid/${txId}?chain=testnet`} variant="soft" target="_blank" level="body-sm" color="neutral">
                <strong>View on Stacks Explorer</strong>
              </Link>
            </Alert>
            { sourceSubmittedSuccess 
              ? 
                  <Alert color="success">
                    <Badge anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }} 
                      size="sm" 
                      badgeInset="0 0 -1.3em 3.5em" 
                      variant="soft"
                      badgeContent={formatDate(sourceSubmittedSuccess)} 
                      color="primary">
                      Transaction submitted to {source} successfully.
                    </Badge>
                  </Alert>
              : <Typography>
                  <Skeleton>
                    Lorem ipsum is placeholder text commonly used in the graphic, print, and
                    publishing industries.
                  </Skeleton>
                </Typography>}
          </div>
        </div>
        <div>
          <Chip>To</Chip>
        </div>
        <div style={{
          display: 'flex',
          padding: '1em',
          gap: '1em',
        }}>
          <div>
            <img src={destinationIcon} class="protocol-logo" />
          </div>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1.2em',
          }}>
            { destinationTx
              ? <Alert color="neutral">
                  <Badge anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }} 
                    size="sm" 
                    badgeInset="0 0 -1.3em 3.5em" 
                    variant="soft"
                    badgeContent={formatDate(destinationSubmittedSuccess)} 
                    color="primary">
                    Submitted claim to {destination}: 
                    <Link href={`https://explorer.solana.com/tx/${destinationTx}?cluster=devnet`} variant="soft" target="_blank" level="body-sm" color="neutral">
                      <strong>View on Solana Explorer</strong>
                    </Link>
                  </Badge>
                </Alert> : ""}
            { destinationFailed
              ? <Alert color="danger">
                  Transaction failed to submit to {destination}, contact support.
                </Alert>
              : destinationSubmittedSuccess
                ? <Alert color="success">

                    <Badge anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }} 
                      size="sm" 
                      badgeInset="0 0 -1.3em 3.5em" 
                      variant="soft"
                      badgeContent={formatDate(destinationSubmittedSuccess)} 
                      color="primary">
                      Transaction submitted to {destination} successfully.
                    </Badge>
                  </Alert>
                : <Typography>
                    <Skeleton>
                      Lorem ipsum is placeholder text commonly used in the graphic, print, and
                      publishing industries.
                    </Skeleton>
                  </Typography>
            }
            { claimMinted 
              ? <Alert color="success">

                  <Badge anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }} 
                    size="sm" 
                    badgeInset="0 0 -1.3em 3.5em" 
                    variant="soft"
                    badgeContent="just now"
                    color="primary">
                    Mint successfully claimed: 
                    <Link href={`https://explorer.solana.com/tx/${mintTx}?cluster=devnet`} variant="soft" target="_blank" level="body-sm" color="success">
                      <strong>View on Solana Explorer</strong>
                    </Link>
                  </Badge>
                </Alert>
              : ""
            }
          </div>
        </div>

        <Typography level="h4" variant="plain">Claim</Typography>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '.5em',
          justifyContent: 'center',
          textAlign: 'center',
        }}>
          <Button
            color="neutral"
            disabled={walletConnected || !destinationSubmittedSuccess}
            loading={walletLoading && !walletConnected}
            onClick={connectWallet}
            size="lg"
            variant="soft">
              {walletConnected && walletAddress
                ? FormatAccount(walletAddress)
                : "Connect Phantom Wallet"}
          </Button>
          <br/>
          { walletConnected && loadingClaim && 
              <div>
                <CircularProgress color="neutral" size="md" />
              </div>}

          { !loadingClaim && claim &&
              <div>
                <Typography>Found claim</Typography>
                <Typography level="h4">{claim.amount} wSTX</Typography>
              </div> }
          { !loadingClaim && !claim && walletConnected &&
              <Typography>No claim found for this transaction</Typography>}
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '.5em',
            justifyContent: 'center',
            textAlign: 'center',
          }}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
            }}>
                <Button
                  color="primary"
                  disabled={!walletConnected || !destinationSubmittedSuccess || !claim || claimMinted}
                  loading={claimingClaim}
                  onClick={function() { claimClaim(claim.id, claimPda) }}
                  size="lg"
                  variant="soft">
                    Claim wSTX
                </Button>
            </div>
          </div>

        </div>
      </div>
    )
  }, [
    walletConnected,
    walletLoading,
    claim,
    claimingClaim,
    creatingWstxAccount,
    sourceSubmittedSuccess, 
    destinationSubmittedSuccess, 
    destinationFailed, 
    destinationTx]);

  return (
    <div class="bridge">
      <Header />
      <div style={{
        borderRadius: '.9em',
        backgroundColor: 'rgba(255, 255, 255)',
        padding: '1em',
        width: '680px'
      }}>
        { loading ? 
            loadingBlock() :
            txExists  ? txExistsBlock() : txDoesntExistBlock()} 
      </div>
      <Footer />
    </div>
  )
}

export default Transaction;