import React, { createContext, useContext, useEffect, useState } from 'react';
import useWebSocket from 'react-use-websocket';

const BROADCAST_URL = 'wss://testnet.byphrost.com/broadcast/';

const TxBroadcastContext = createContext();

export function useTxBroadcast() {
    return useContext(TxBroadcastContext);
}

export const TxBroadcastProvider = ({ children, type }) => {
  const { sendMessage, lastMessage, readyState } = useWebSocket(BROADCAST_URL);

  const subscribe = (protocol, txId) => {
    sendMessage(JSON.stringify({ protocol, transaction: txId }));
  };

  useEffect(() => {
    if (readyState === 1) {
      console.log('connected to tx broadcast server');
    }
  });

  useEffect(() => {
    if (lastMessage) {
      console.log('received message', lastMessage);
    }
  }, [lastMessage]);

  return (
      <TxBroadcastContext.Provider value={{ subscribe, lastMessage }}>
        {children}
      </TxBroadcastContext.Provider>
  );
};