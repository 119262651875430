import { AnchorProvider, Program } from '@project-serum/anchor';
import { Connection, Transaction, PublicKey, clusterApiUrl, SystemProgram } from '@solana/web3.js';
import { TOKEN_PROGRAM_ID } from '@solana/spl-token';
import * as anchor from "@coral-xyz/anchor";
import CryptoJS from 'crypto-js';
import idl from '../../assets/idls/wstx_minter.json'

const url = 'https://quaint-indulgent-firefly.solana-devnet.quiknode.pro/7f5d1a5d7c6548dbed64cb6db6c515b6e25e447a';
const connection = new Connection(url, 'confirmed');
const provider = new AnchorProvider(connection, window.solana, {
  preflightCommitment: 'confirmed',
});

const programId = '9UZ9CM7UGmUdgDAF1bAfkTs6AThvRmSr6KtzJfQCwTfC';
const program = new Program(idl, programId, provider);

export const ConnectToPhantom = async () => {
  const resp = await window.phantom.solana.connect()
  const connection = new Connection(clusterApiUrl(process.env.REACT_APP_SOL_CLUSTER), 'confirmed');
  const balance = await connection.getBalance(resp.publicKey);

  return {
    publicKey: resp.publicKey,
    balance
  }
}

export const GetWSTXClaim = async (id) => {
  const hashId = (id) => {
    const hash = CryptoJS.SHA256(id);
    const hashWordArray = CryptoJS.enc.Hex.parse(hash.toString(CryptoJS.enc.Hex));
    const truncatedHashWordArray = CryptoJS.lib.WordArray.create(hashWordArray.words.slice(0, 4));
    return truncatedHashWordArray.toString(CryptoJS.enc.Hex);
  }

  const claimant = new PublicKey(window.solana.publicKey);
  const [claimPda, _] = await PublicKey.findProgramAddress(
    [Buffer.from("bstx_mint_claim"), claimant.toBytes(), Buffer.from(hashId(id))],
    program.programId
  );
  try {
    console.log(claimPda.toBase58())
    console.log(id)
    console.log(hashId(id))
    console.log(program.programId)
    console.log(program.account)
    let claim = await program.account.claim.fetch(claimPda);
    return {
      claim,
      claimPda
    };
  } catch (e) {
    console.log(e);
    return {};
  }
};


export const MintClaim = async (id, claimPda) => {
  const [ mint ] = await PublicKey.findProgramAddress(
    [Buffer.from("bstx")],
    program.programId
  );

  const destination = await anchor.utils.token.associatedAddress({
    mint: mint,
    owner: new PublicKey(window.solana.publicKey),
  });

  const instructions = program.instruction
    .mintClaim(id, {
      accounts: {
        claim: claimPda,
        mint,
        destination,
        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: SystemProgram.programId,
        rent: anchor.web3.SYSVAR_RENT_PUBKEY,
        associatedTokenProgram: anchor.utils.token.ASSOCIATED_PROGRAM_ID,
        payer: new PublicKey(window.solana.publicKey),
      }
    }
  );

  const tx = new Transaction();
  tx.feePayer = new PublicKey(window.solana.publicKey);
  tx.recentBlockhash = (await connection.getRecentBlockhash()).blockhash;
  tx.add(instructions);

  const signed = await window.solana.signAndSendTransaction(tx);
  await connection.confirmTransaction(signed);
  return signed;
};