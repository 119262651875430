import { AccountsApi, Configuration } from '@stacks/blockchain-api-client';
import { AppConfig, UserSession, showConnect } from '@stacks/connect';
import { Buffer } from 'buffer';
import { openContractCall } from '@stacks/connect';
import {
  Cl,
  FungibleConditionCode,
  makeStandardSTXPostCondition,
  uintCV, 
} from '@stacks/transactions';

const VAULT_ADDRESS = process.env.REACT_APP_STX_VAULT;

const appConfig = new AppConfig(['store_write', 'publish_data']);
const apiConfig = new Configuration({
  fetchApi: fetch,
  basePath: process.env.REACT_APP_STX_INSTANCE,
});
const appDetails = {
  name: 'Byphrost',
  icon: window.location.origin + '/logo-cropped.svg',
};

export const ConnectToLeather = async () => {
  const userSession = new UserSession({ appConfig });
  await new Promise((resolve, reject) => {
    showConnect({
      appDetails,
      onFinish: resolve,
      userSession: userSession,
    });
  });

  return userSession;
}

export const STXBalance = async (address) => {
  const accountsApi = new AccountsApi(apiConfig);
  try {
    const response = await accountsApi.getAccountBalance({
      principal: address
    });
    return response.stx.balance; // The balance is returned in microstacks
  } catch (error) {
    console.error('Failed to fetch STX balance:', error);
    return null; // or handle error appropriately
  }
};

export const BridgeFromStacks = async (amount, destinationNetwork, destinationAddress) => {
  console.log(amount, destinationNetwork, destinationAddress)
  return await new Promise((resolve, reject) => {
    const request = {
      amount: amount,
      destination_network: destinationNetwork,
      destination_address: destinationAddress
    };
    const requestString = JSON.stringify(request);
    const requestBase64 = Buffer.from(requestString).toString('base64');

    const functionArgs = [
      uintCV(Number(amount)),
      Cl.stringAscii(requestBase64),
    ];
    
    const postConditionAddress = VAULT_ADDRESS;
    const postConditionCode = FungibleConditionCode.LessEqual;
    const postConditionAmount = Number(amount);
    const postConditions = [
      makeStandardSTXPostCondition(postConditionAddress, postConditionCode, postConditionAmount),
    ];

    const options = {
      contractAddress: VAULT_ADDRESS,
      contractName: 'vault',
      functionName: 'deposit',
      functionArgs,
      postConditions,
      appDetails,
      onFinish: resolve
    };
    
    openContractCall(options).then(console.log).catch(console.error);
  });
}