import { FaCopy } from "react-icons/fa6";
import { FaListOl } from "react-icons/fa6";
import { FaUnlink } from "react-icons/fa";
import { NormalizeBalance, FormatAccount } from "../lib/utils";
import { useCallback } from "react";
import { useWalletsStore } from "../store/walletStore";

function AccountDetails({ contract }) {
  const wallet = useWalletsStore((state) => state[contract?.wallet]);

  return (
    <div>
      <div class={`${true ? "account-info" : "hidden"}`}>
        <label>account</label> 
        <div style={{
          display: 'flex',
          flexDirection: 'column',
        }}>
          <div style={{
            height: '2em',
          }}>
            {FormatAccount(contract?.address || '')}
          </div>
          <div class="account-options">
            <a href="" class="account-button"><FaCopy color="white" size={13} /> Copy address</a>
            <a href="" class="account-button"><FaListOl color="white" size={13} /> Transactions</a>
            <a href="" class="account-button"><FaUnlink color="white" size={13} /> Disconnect</a>
          </div>
        </div>
      </div>
      <div class="account-info">
        <label>balance</label>
        <span>
          {NormalizeBalance(wallet?.assetBalances[contract?.asset], contract?.protocol) || 0} {contract?.asset.toUpperCase()}
        </span>
      </div>
    </div>
  );
}

export default AccountDetails;